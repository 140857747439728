import React, { useState, useEffect } from 'react'
import {
  Box,
  TextField,
  Dialog,
  Typography,
  Grid,
  Button,
  CircularProgress,
  Autocomplete,
  Switch,
  FormControlLabel
} from '@mui/material'
import moment from 'moment'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useGetPlanUsageMutation, useUpdatePlanUsageMutation } from 'services/auth-service'
import Divider from '@mui/material/Divider'
import { planSettingFormFields } from 'config'

const PlanSetting = ({ onClose, open, userId, allWorkspaces, isPartnerAccount }) => {
  const [updatePlan] = useUpdatePlanUsageMutation()
  const [getPlanUsage] = useGetPlanUsageMutation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selectedWorkspace, setSelectedWorkspace] = useState(null)
  const [workspaceData, setWorkspaceData] = useState(null)

  useEffect(() => {
    if (allWorkspaces && allWorkspaces.length > 0) {
      setSelectedWorkspace(allWorkspaces[0])
    }
  }, [allWorkspaces])

  const sendUpdatedPlan = async (updatedData) => {
    try {
      setIsSubmitting(true)
      await updatePlan({
        id: userId,
        data: updatedData
      })
    } catch (error) {
      console.error(error)
    }
    setIsSubmitting(false)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      activeLeads: 0,
      monthlyEmails: 0,
      aiWriterCredits: 0,
      leadsCredits: 0,
      bounceCredit: 0,
      linkedinCredits: 0,
      dailyLeadLimit: 0,
      leadsPhoneNumberExportsPerMonth: 0,
      emailAccountsLimit: 0,
      savedContacts: 0,
      dealPipeline: 0,
      dataEnrichmentCredits: 0,
      buyerIntendDataTopiscs: 0,
      freeTrialExpiresAt: null,
      selectedWorkspace: selectedWorkspace?._id || '',
      perDay: 0,
      perHour: 0,
      perMin: 0,
      buyerIntendData: false,
      advancedFilters: false,
      abTesting: false,
      aiFilters: false,
      mailboxConnection: false,
      customDomainTracking: false,
      globalBlocklist: false,
      emailWarmup: false,
      multiChannelLinkedinOutreach: false,
      unibox: false,
      aiAutoReplies: false,
      zapierIntegration: false,
      hubspotIntegration: false,
      salesforceIntegration: false,
      pipedriveIntegration: false,
      webhookIntegration: false,
      advancedApis: false
    },
    validationSchema: Yup.object({
      activeLeads: Yup.number().integer(),
      monthlyEmails: Yup.number().integer(),
      aiWriterCredits: Yup.number().integer(),
      leadsCredits: Yup.number().integer(),
      linkedinCredits: Yup.number().integer(),
      bounceCredit: Yup.number().integer(),
      dailyLeadLimit: Yup.number().integer(),
      leadsPhoneNumberExportsPerMonth: Yup.number().integer(),
      emailAccountsLimit: Yup.number().integer(),
      savedContacts: Yup.number().integer(),
      dealPipeline: Yup.number().integer(),
      dataEnrichmentCredits: Yup.number().integer(),
      buyerIntendDataTopiscs: Yup.number().integer(),
      freeTrialExpiresAt: Yup.date().nullable(),
      selectedWorkspace: Yup.string().required('Workspace is required'),
      perDay: Yup.number().integer(),
      perHour: Yup.number().integer(),
      buyerIntendData: Yup.boolean(),
      advancedFilters: Yup.boolean(),
      abTesting: Yup.boolean(),
      aiFilters: Yup.boolean(),
      mailboxConnection: Yup.boolean(),
      customDomainTracking: Yup.boolean(),
      globalBlocklist: Yup.boolean(),
      emailWarmup: Yup.boolean(),
      multiChannelLinkedinOutreach: Yup.boolean(),
      unibox: Yup.boolean(),
      aiAutoReplies: Yup.boolean(),
      zapierIntegration: Yup.boolean(),
      hubspotIntegration: Yup.boolean(),
      salesforceIntegration: Yup.boolean(),
      pipedriveIntegration: Yup.boolean(),
      webhookIntegration: Yup.boolean(),
      advancedApis: Yup.boolean()
    }),
    onSubmit: async (values) => {
      await sendUpdatedPlan(values)
      await onClose()
    }
  })

  useEffect(() => {
    const fetchWorkspaceData = async () => {
      if (selectedWorkspace) {
        try {
          const { data } = await getPlanUsage(selectedWorkspace._id)
          setWorkspaceData(data)

          formik.setValues({
            ...formik.values,
            selectedWorkspace: selectedWorkspace._id,
            activeLeads: data?.usage?.activeLeads,
            monthlyEmails: data?.usage?.monthlyEmails,
            aiWriterCredits: data?.usage?.aiWriterCredits,
            leadsCredits: data?.usage?.leadsCredits,
            linkedinCredits: data?.usage?.linkedinCredits,
            bounceCredit: data?.usage?.bounceCredit,
            dailyLeadLimit: data?.usage?.dailyLeadLimit,
            leadsPhoneNumberExportsPerMonth: data?.usage?.leadsPhoneNumberExportsPerMonth,
            emailAccountsLimit: data?.usage?.emailAccountsLimit,
            savedContacts: data?.usage?.savedContacts,
            dealPipeline: data?.usage?.dealPipeline,
            dataEnrichmentCredits: data?.usage?.dataEnrichmentCredits,
            buyerIntendDataTopiscs: data?.usage?.buyerIntendDataTopiscs,
            perDay: data?.plan?.apiRateLimit?.limits?.perDay,
            perHour: data?.plan?.apiRateLimit?.limits?.perHour,
            perMin: data?.plan?.apiRateLimit?.limits?.perMin,
            freeTrialExpiresAt:
              data?.usage?.freeTrialExpiresAt ||
              data?.plan?.freeTrialExpiresAt,
            buyerIntendData: data?.usage?.buyerIntendData,
            advancedFilters: data?.usage?.advancedFilters,
            abTesting: data?.usage?.abTesting,
            aiFilters: data?.usage?.aiFilters,
            mailboxConnection: data?.usage?.mailboxConnection,
            customDomainTracking: data?.usage?.customDomainTracking,
            globalBlocklist: data?.usage?.globalBlocklist,
            emailWarmup: data?.usage?.emailWarmup,
            multiChannelLinkedinOutreach: data?.usage?.multiChannelLinkedinOutreach,
            unibox: data?.usage?.unibox,
            aiAutoReplies: data?.usage?.aiAutoReplies,
            zapierIntegration: data?.usage?.zapierIntegration,
            hubspotIntegration: data?.usage?.hubspotIntegration,
            salesforceIntegration: data?.usage?.salesforceIntegration,
            pipedriveIntegration: data?.usage?.pipedriveIntegration,
            webhookIntegration: data?.usage?.webhookIntegration,
            advancedApis: data?.usage?.advancedApis
          })
        } catch (error) {
          toast.error(`${error.message || "Error fetching workspace data"}`)
        }
      }
    }

    fetchWorkspaceData()
  }, [selectedWorkspace])

  const handleClose = () => {
    onClose()
  }
  const date = new Date(formik.values.freeTrialExpiresAt)
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const year = date.getFullYear()
  const formattedDate = `${month}-${day}-${year}`

  const paidUser = workspaceData?.plan?.subscription?.sendingWarmup?.expiresAt
  const dates = new Date(paidUser)
  const months = (dates.getMonth() + 1).toString().padStart(2, '0')
  const days = dates.getDate().toString().padStart(2, '0')
  const years = dates.getFullYear()
  const formattedPaidDate = `${months}-${days}-${years}`
  const weeklyTrial = workspaceData?.plan?.subscription?.sendingWarmup?.planType

  const handleKeyDown = (e) => {
    // Prevent entering invalid characters
    const regex = /^[+-.]*$/ // Only allow digits
    if (regex.test(e.key)) {
      e.preventDefault()
    }
  }

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <form
          noValidate
          onSubmit={formik.handleSubmit}
          className={{ width: '500px' }}
        >
          <Box
            maxWidth='sm'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: 'full',
              margin: '1rem',
              bgcolor: 'white',
              padding: '1rem'
            }}
          >
            <Typography
              variant='h3'
              component='h2'
              sx={{ cursor: 'pointer', color: 'rgba(40, 40, 123, 1)' }}
            >
              Plan Settings
            </Typography>
            {paidUser === undefined || weeklyTrial === 'weekly'
              ? (<></>)
              : (
                <Typography
                  variant='h6'
                  component='h2'
                  sx={{ cursor: 'pointer', color: 'red' }}
                >
                  The expiration date cannot be modified for paid accounts.
                </Typography>
                )}
            {planSettingFormFields.map((section) => (
              <Box key={section.title} sx={{ mt: 3 }}>
                {!(section?.dateFields || section?.workspaceField) &&
                  <Divider sx={{ mt: 3, mb: 2 }} textAlign='center'>
                    <Typography variant='h6' color='textSecondary'>
                      {section.title}
                    </Typography>
                  </Divider>}
                <Grid container spacing={2}>
                  {section.textFields?.map((field) => (
                    <Grid item xs={12} md={6} key={field.name}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, mb: 1 }}>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            fontWeight: 700,
                            lineHeight: '26px',
                            color: 'rgb(129, 129, 176)'
                          }}
                        >
                          {field.label}
                        </Typography>
                      </Box>
                      <TextField
                        fullWidth
                        variant='outlined'
                        placeholder={field.label}
                        name={field.name}
                        value={formik.values[field.name]}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        onKeyDown={handleKeyDown}
                        error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
                        helperText={formik.touched[field.name] && formik.errors[field.name]}
                        sx={{
                          '& div': {
                            pl: 0.3,
                            borderRadius: '0.7rem',
                            width: '20rem',
                            mt: '-0.2rem'
                          },
                          '& div fieldset': {
                            border: '1px solid #E4E4E5'
                          },

                          '& div input': {
                            py: 1.3,
                            fontSize: '13px',
                            fontWeight: 400,
                            lineHeight: '16px',
                            letterSpacing: '0em',
                            '&::placeholder': {
                              color: 'rgba(40, 40, 123, 0.5)'
                            }
                          }
                        }}
                      />
                    </Grid>
                  ))}
                  {(section?.dateFields || section?.workspaceField) &&
                    <Grid item xs={12} md={6}>
                      {section.dateFields?.map((field) => (
                        <Box key={field.name}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, mb: 1 }}>
                            <Typography
                              sx={{
                                fontSize: '16px',
                                fontWeight: 700,
                                lineHeight: '26px',
                                color: 'rgb(129, 129, 176)'
                              }}
                            >
                              {formattedPaidDate !== 'NaN-NaN-NaN' && weeklyTrial !== 'weekly'
                                ? `Paid User ExpiresAt ${formattedPaidDate}`
                                : `Update Free Trial ExpiresAt ${formattedDate}`}
                            </Typography>
                          </Box>
                          <TextField
                            disabled={
                              formattedPaidDate !== 'NaN-NaN-NaN' && weeklyTrial !== 'weekly' ? 'disabled' : ''
                            }
                            fullWidth
                            type='date'
                            variant='outlined'
                            name={field.name}
                            placeholder={field.value}
                            value={moment(formik.values[field.name]).format(
                              'YYYY-MM-DD'
                            )}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            onKeyDown={handleKeyDown}
                            error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
                            helperText={formik.touched[field.name] && formik.errors[field.name]}
                            sx={{
                              '& div': {
                                pl: 0.3,
                                borderRadius: '0.7rem',
                                mt: '-0.2rem'
                              },
                              '& div fieldset': {
                                border: '1px solid #E4E4E5'
                              },

                              '& div input': {
                                py: 1.3,
                                fontSize: '13px',
                                fontWeight: 400,
                                lineHeight: '16px',
                                letterSpacing: '0em',
                                '&::placeholder': {
                                  color: 'rgba(40, 40, 123, 0.5)'
                                }
                              }
                            }}
                          />
                        </Box>
                      ))}
                      {section.workspaceField?.map((field) => (
                        <Autocomplete
                          key={field.name}
                          id='workspace-autocomplete'
                          options={allWorkspaces}
                          getOptionLabel={(option) => option.name}
                          value={
                            allWorkspaces.find((workspace) => workspace._id === formik.values[field.name]) || null
                          }
                          onChange={(event, newValue) => {
                            setSelectedWorkspace(newValue)
                          }}
                          disabled={isPartnerAccount}
                          onBlur={formik.handleBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={field.label}
                              error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
                              helperText={formik.touched[field.name] && formik.errors[field.name]}
                            />
                          )}
                        />
                      ))}
                    </Grid>}
                  {section.switches?.map((sw) => (
                    <Grid item xs={6} key={sw.name}>
                      <FormControlLabel
                        control={
                          <Switch
                            name={sw.name}
                            checked={formik.values[sw.name]}
                            onChange={formik.handleChange}
                          />
                        }
                        label={sw.label}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ))}

            <Box sx={{ display: 'flex', justifyContent: 'end', mt: '2rem' }}>
              <SaveButton
                onClick={formik.handleSubmit}
                isSubmitting={isSubmitting}
              />
            </Box>
          </Box>
        </form>
      </Dialog>
    </>
  )
}

export default PlanSetting

const SaveButton = ({ onClick, isSubmitting }) => {
  return (
    <Button
      sx={{
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '18px',
        py: 1,
        px: 2
      }}
      variant='contained'
      type='submit'
      onClick={onClick}
    >
      {isSubmitting
        ? (
          <>
            <CircularProgress
              color='inherit'
              size={20}
              thickness={5}
              sx={{ mr: 1 }}
            />
            Saving
          </>
          )
        : (
          <>Save</>
          )}
    </Button>
  )
}
